/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react';
import { AiOutlinePlus } from 'react-icons/ai';
import Collapse from '@material-ui/core/Collapse';
import Axios from 'axios';
import { Base64 } from 'js-base64';
import api from '../../../services/api';
import {
  BlocoCadastro,
  DadosPesssoaisItemLabel,
  BotaoCadastrar,
  Div,
  ModalAlert,
} from '../styles';
import { store } from '../../../store';
import { Integer } from '../../../services/masks';

function CadastrarEndereco(props) {
  const [visible, setVisible] = useState(false);
  const [tipoEnd, setTipoEnd] = useState('');
  const [pais, setPais] = useState('Brasil');
  const [uf, setUF] = useState('');
  const [municipio, setMunic] = useState('');
  const [bairro, setBairro] = useState('');
  const [rua, setRua] = useState('');
  const [infCep, setCep] = useState('');
  const [numero, setNumero] = useState('');
  const [complemento, setCompl] = useState('');
  // const [listaUF, setListaUF] = useState(props);
  const [listaMun, setListaMun] = useState('');
  const [msg, setMsg] = useState('');
  const [alerta, setAlerta] = useState(false);
  const [flag, setFlag] = useState(false);

  // const listaUF = props;

  const { token } = store.getState().auth;

  if (props === undefined) {
    return <></>;
  }

  const estados = props;
  const listaUF = estados.map((n, index) => (
    <option
      key={index}
      value={n.descricao}
      onClick={(e) => setUF(e.target.value)}
    >
      {n.descricao}
    </option>
  ));

  function HandleToggleVisible() {
    setVisible(!visible);
  }

  async function handleBuscaCep(cep) {
    setCep(Integer(cep));
    const tamanho = cep.length;
    if (tamanho === 8) {
      const responseCPF = await Axios.get(
        `https://viacep.com.br/ws/${cep}/json/`
      );
      setUF(responseCPF.data.uf);
      setMunic(responseCPF.data.localidade);
      setBairro(responseCPF.data.bairro);
      setRua(responseCPF.data.logradouro);
      setCep(cep);
    }
  }

  async function handleChangeUF(v) {
    if (v !== '' && v !== null && v !== undefined) {
      try {
        const parametrosLista = Base64.encode(
          `{"token": "${token}", "pais": "${pais}", "estado": "${v}"}`
        );
        const parametrosListaFinal = Base64.encode(parametrosLista);
        const responseLista = await api.get(
          `/wsPaisEstadoMunicipio.rule?sys=PRT&Entrada=${parametrosListaFinal}`
        );
        const lista = responseLista.data;
        setListaMun(
          lista.dados.map((n, index) => (
            <option key={index} onClick={(e) => setUF(e.target.value)}>
              {n.descricao}
            </option>
          ))
        );
      } catch {
        setListaMun('');
      }
    }
    setUF(v);
  }

  async function handleAddEndereco() {
    const parametros = Base64.encode(
      `{"token": "${token}", "tipoEndereco": "${tipoEnd}",
        "pais": "${pais}", "uf":"${uf}",
        "municipio": "${municipio}", "bairro": "${bairro}",
        "rua": "${rua}", "cep": "${infCep}",
        "numero": "${numero}", "complemento": "${complemento}"}`
    );
    const parametrosFinal = Base64.encode(parametros);
    await api
      .post(`/wsEndereco.rule?sys=PRT&Entrada=${parametrosFinal}`)
      .then(() => {
        setMsg('Endereço atualizado com sucesso');
        setAlerta(true);
        setFlag(true);
      })
      .catch(() => {
        setMsg(
          'Não foi possível atualizar o endereço agora. Tente novamente em alguns minutos.'
        );
        setAlerta(true);
      });
  }

  function HandleToggleAlerta() {
    setAlerta(!alerta);
    if (flag === true) {
      document.location.reload(true);
    }
  }

  return (
    <BlocoCadastro>
      <ModalAlert visible={alerta}>
        <popup-inner>
          <pop-up-sair-texto>{msg}</pop-up-sair-texto>
          <div>
            <button type="button" onClick={HandleToggleAlerta}>
              OK
            </button>
          </div>
        </popup-inner>
      </ModalAlert>
      <dados-pessoais-abrir-cadastro onClick={HandleToggleVisible}>
        <dados-pessoais-subtitulo-white>
          Cadastrar novo endereço
        </dados-pessoais-subtitulo-white>
        <cadastro-botao-frame>
          <cadastro-botao visible={visible}>
            <AiOutlinePlus
              size={30}
              style={
                visible
                  ? {
                      transform: 'rotate(-135deg)',
                      transition: 'transform .2s ease-in-out',
                    }
                  : {
                      transform: 'rotate(0deg)',
                      transition: 'transform .2s ease-in-out',
                    }
              }
            />
          </cadastro-botao>
        </cadastro-botao-frame>
      </dados-pessoais-abrir-cadastro>
      <Div>
        <Collapse in={visible}>
          <form>
            <dados-pessoais-bloco-parte02>
              <dados-pesssoais-item-endereco>
                <DadosPesssoaisItemLabel>CEP</DadosPesssoaisItemLabel>
                <dados-pessoais-form>
                  <dados-pessoais-form-frame>
                    <input
                      type="text"
                      name="cep"
                      maxLength="8"
                      value={infCep}
                      onChange={(e) => handleBuscaCep(e.target.value)}
                    />
                  </dados-pessoais-form-frame>
                </dados-pessoais-form>
              </dados-pesssoais-item-endereco>

              <dados-pesssoais-item-endereco>
                <DadosPesssoaisItemLabel>Número</DadosPesssoaisItemLabel>
                <dados-pessoais-form>
                  <dados-pessoais-form-frame>
                    <input
                      name="numero"
                      type="text"
                      onChange={(e) => setNumero(e.target.value)}
                      defaultValue={numero}
                    />
                  </dados-pessoais-form-frame>
                </dados-pessoais-form>
              </dados-pesssoais-item-endereco>

              <dados-pesssoais-item-endereco>
                <DadosPesssoaisItemLabel>Bairro</DadosPesssoaisItemLabel>
                <dados-pessoais-form>
                  <dados-pessoais-form-frame>
                    <input name="bairro" type="text" defaultValue={bairro} />
                  </dados-pessoais-form-frame>
                </dados-pessoais-form>
              </dados-pesssoais-item-endereco>

              <dados-pesssoais-item-endereco>
                <DadosPesssoaisItemLabel>Estado</DadosPesssoaisItemLabel>
                <dados-pessoais-form>
                  <dados-pessoais-form-frame>
                    {/* <input name="uf" type="text" defaultValue={uf} /> */}
                    <select
                      name="uf"
                      type="text"
                      value={uf}
                      onChange={(e) => setUF(e.target.value)}
                      onMouseLeave={(e) => handleChangeUF(e.target.value)}
                      onRateChange={(e) => handleChangeUF(e.target.value)}
                    >
                      <option value=""> </option>
                      {listaUF}
                    </select>
                  </dados-pessoais-form-frame>
                </dados-pessoais-form>
              </dados-pesssoais-item-endereco>

              <dados-pesssoais-item-endereco>
                <DadosPesssoaisItemLabel>Tipo</DadosPesssoaisItemLabel>
                <dados-pessoais-form>
                  <dados-pessoais-form-frame>
                    <select
                      nome="tipo"
                      onChange={(e) => setTipoEnd(e.target.value)}
                      defaultValue={tipoEnd}
                    >
                      <option value="">Escolher opção</option>
                      <option value="R">Residencial</option>
                      <option value="C">Comercial</option>
                      <option value="D">Recado</option>
                    </select>
                  </dados-pessoais-form-frame>
                </dados-pessoais-form>
              </dados-pesssoais-item-endereco>
            </dados-pessoais-bloco-parte02>

            <dados-pessoais-bloco-parte02>
              <dados-pesssoais-item-endereco>
                <DadosPesssoaisItemLabel>Logradouro</DadosPesssoaisItemLabel>
                <dados-pessoais-form>
                  <dados-pessoais-form-frame>
                    <input
                      name="logradouro"
                      type="text"
                      defaultValue={rua}
                      // onChange={handleChangePais}
                    />
                  </dados-pessoais-form-frame>
                </dados-pessoais-form>
              </dados-pesssoais-item-endereco>

              <dados-pesssoais-item-endereco>
                <DadosPesssoaisItemLabel>Complemento</DadosPesssoaisItemLabel>
                <dados-pessoais-form>
                  <dados-pessoais-form-frame>
                    <input
                      name="complemento"
                      type="text"
                      onChange={(e) => setCompl(e.target.value)}
                      defaultValue={complemento}
                    />
                  </dados-pessoais-form-frame>
                </dados-pessoais-form>
              </dados-pesssoais-item-endereco>

              <dados-pesssoais-item-endereco>
                <DadosPesssoaisItemLabel>Município</DadosPesssoaisItemLabel>
                <dados-pessoais-form>
                  <dados-pessoais-form-frame>
                    {/* <input name="municipio" type="text" defaultValue={municipio} /> */}
                    <select
                      name="municipio"
                      type="text"
                      value={municipio}
                      onChange={(e) => setMunic(e.target.value)}
                    >
                      {listaMun}
                    </select>
                  </dados-pessoais-form-frame>
                </dados-pessoais-form>
              </dados-pesssoais-item-endereco>

              <dados-pesssoais-item-endereco>
                <DadosPesssoaisItemLabel>País</DadosPesssoaisItemLabel>
                <dados-pessoais-form>
                  <dados-pessoais-form-frame>
                    <input
                      name="pais"
                      type="text"
                      onChange={(e) => setPais(e.target.value)}
                      defaultValue={pais}
                    />
                  </dados-pessoais-form-frame>
                </dados-pessoais-form>
              </dados-pesssoais-item-endereco>
              <dados-pesssoais-item-copy>
                <BotaoCadastrar onClick={handleAddEndereco}>
                  Cadastrar
                </BotaoCadastrar>
              </dados-pesssoais-item-copy>
            </dados-pessoais-bloco-parte02>
          </form>
        </Collapse>
      </Div>
    </BlocoCadastro>
  );
}

export default CadastrarEndereco;
