import styled from 'styled-components';

export const SecaoFooter = styled.div`
  font-weight: 300;
  position: static;
  bottom: 0px;
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 60px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #fff;
  box-shadow: 0 -4px 3px 0 rgba(0, 0, 0, 0.25);

  secao-footer-parte1 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 30%;
    height: 64px;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    @media screen and (max-width: 767px) {
      height: 60px;
    }
  }
  secao-footer-parte2 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 40%;
    height: 64px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    @media screen and (max-width: 767px) {
      height: 60px;
    }
  }

  secao-footer-parte3 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 30%;
    height: 64px;
    padding-top: 10px;
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    @media screen and (max-width: 767px) {
      height: 60px;
    }
  }

  footer-telefones-item {
    width: 110px;
    margin-right: 10px;
    margin-left: 10px;
    @media screen and (max-width: 991px) {
      display: none;
    }
  }

  footer-telefones-divisoria {
    width: 1px;
    height: 47px;
    background-color: #fe5a17;
    @media screen and (max-width: 991px) {
      display: none;
    }
  }

  secao-footer-squares {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 15px;
    height: 63px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    @media screen and (max-width: 767px) {
      height: 60px;
    }
  }

  footer-logo-1 {
    width: 75px;
    @media screen and (max-width: 767px) {
      margin-right: 50px;
      margin-left: 50px;
    }
  }

  square-footer-roxo {
    width: 16px;
    height: 16px;
    background-color: #96005a;
  }

  square-footer-laranja {
    width: 16px;
    height: 16px;
    background-color: #fe5a17;
  }

  square-footer-verde {
    width: 16px;
    height: 16px;
    background-color: #008240;
  }

  square-footer-cyan {
    width: 16px;
    height: 16px;
    background-color: #0189b4;
  }
`;

export const BotaoTop = styled.div`
  position: static;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: auto;
  z-index: 5;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 100px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;

  botao-top-link {
    padding: 5px 8px;
    background-color: #fe5a17;
    font-size: 24px;
  }
`;

export const FooterTelefonesTexto = styled.div`
  color: #0c2d83;
  font-size: 12px;
`;

export const FooterTelefonesLegenda = styled.div`
  margin-top: 5px;
  margin-bottom: 5px;
  color: #0c2d83;
  font-size: 8px;
`;
